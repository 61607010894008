import { useEffect } from "react";
import "./app.css";
import "./custom.css";

export default () => {
    const data = [
        { id: "ff6e8291-8afc-489a-818f-485c6f350ed1", name: "Hi Toastie CBD", available: true },
        { id: "3b690ec9-06ec-4370-8059-0b6cfd972c7a", name: "Hi Toastie Newmarket", available: true },
    ];

    const onOrder = (link) => {
        window.location.href = `https://order.tabin.co.nz/restaurant/${link}`;
    };

    return (
        <>
            <div className="restaurant-list">
                <img
                    src={`https://tabin182909-prod.s3.ap-southeast-2.amazonaws.com/protected/ap-southeast-2%3A25450a52-7a40-45ea-a868-a9d98c6ff86a/2023-03-18_02%3A25%3A46.986-KakaoTalk_20230413_170704065.webp`}
                    className="restaurant-list-logo"
                    alt="logo"
                />
                <div className="h4 text-center mb-6">
                    At Toastie, indulge in our exceptionally tasty toasties, crafted from premium ingredients. Ready to savor the flavor? Click "Order
                    Here" to begin!
                </div>
                {data &&
                    data.map((restaurant, index) => (
                        <div key={restaurant.id}>
                            {index != 0 && <div className="separator-4"></div>}
                            <div className="restaurant-list-item">
                                <div className="restaurant-name">{restaurant.name}</div>
                                <button
                                    className={`button ${restaurant.available ? "" : "disabled"}`}
                                    onClick={() => {
                                        restaurant.available && onOrder(restaurant.id);
                                    }}
                                >
                                    {restaurant.available ? "Order Here" : "Unavailable"}
                                </button>
                            </div>
                        </div>
                    ))}
            </div>
        </>
    );
};
